import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import '../styles/ValueProposition.css';
import { registerUrl, DefaultGtag } from '../utils/misc'
import YouTubeIcon from '@mui/icons-material/YouTube';
import Iframe from 'react-iframe'
import '../styles/WhyTas.css'
import '../styles/index.css'
import GreenMessage from '../components/GreenMessage';
import JoinUsPage from '../components/JoinUs';
import { TaskAlt } from "@mui/icons-material";


const mainStyles = {
  display: "flex",
  flexDirection: "column"
}

const firstSection = {
  display: "flex",
  padding: "0 2rem",
}

const iframeContainerStyles = {
  background: "#121212",
  display: "flex",
  position: "relative",
  width: "100%",
  maxWidth: "612px",
  maxHeight: "350px",
  height: "378px",
  padding: "0.5rem",
  borderRadius: "6px"
}

const youtubeIconStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
}

function Card({ t, i18n, iconName, title, description, ...props }) {
  return <div className="custom-card">
    <div className="image-container">
      <img src={`/static/media/icons/${iconName}-221130.svg`} alt="online menu with search" width="48" height="48" />
    </div>
    <h3>{title}</h3>
    <span className="second-title">{description}</span>
  </div>
}

// TODO: translate alt
function WideCard({ imgName, title, description, linkTo }) {
  const { t } = useTranslation();

  return <div className="custom-card wide">
    <div className="text-container">
      <div>
        <h3 className="title">{title}</h3>
        <div className="second-title">{description}</div>
        <Link to={linkTo}><div className="tas-button" style={{ visibility: !linkTo ? "hidden" : "visible" }}>{t('read-more')}</div></Link> {/** TODO: use resource */}
      </div>
    </div>
    <div className="image-container">
      <div style={{ background: `url("/static/media/images/${imgName}.png") no-repeat center center/cover, url("/static/media/images/${imgName}.webp") no-repeat center center/cover` }}></div>
    </div>
  </div>
}

const IndexPage = () => {
  const { t } = useTranslation();
  const [rUrl, setRUrl] = React.useState('https://admin.takeaseat.io/register?solution=all')

  useEffect(() => {
    setTimeout(function () {
      // need delay, otherwise not working on firefox...
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, 100);
    setRUrl(registerUrl())
  }, [])

  return (
    <Layout>
      <main style={mainStyles} className="homepage">
        <div style={firstSection} className="page ValueProposition">
          <div className="subcontainer" style={{ minHeight: "unset", height: "100%" }}> {/** Remove marginTop */}
            <div className="half-page half-page-left" style={{ justifyContent: "center" }}>
              <div>
                <div className="quote-container" style={{ maxWidth: "unset" }}>
                  <h1 className="title" style={{ marginTop: "0" }}>{t(`value-proposition.title.0`)}</h1>
                  <div className="subtitle" style={{ marginTop: "2rem" }} >{t(`value-proposition.subtitle`)}</div>
                  <div className="second-title">
                    <div><TaskAlt />{t(`value-proposition.quotes.0`)}</div>
                    <div><TaskAlt />{t(`value-proposition.quotes.1`)}</div>
                    <div><TaskAlt />{t(`value-proposition.quotes.2`)}</div>
                    <div><TaskAlt />{t(`value-proposition.quotes.3`)}</div>
                  </div>
                </div>
                <div className="index-buttons" >
                  <a href={rUrl} rel="noreferrer" className="tas-button" /** todo: revamp button */
                    onClick={() => {
                      window.gtag_report_conversion(rUrl + "&tier=standard&source=landing.valueproposition")
                      window.gtag('event', 'click_on_get_started');
                    }}>
                    <div>{t(`value-proposition.free-sign-up`)}</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="half-page half-page-right">
              <div style={{ width: "100%", alignItems: "center", justifyContent: "end", display: "flex", margin: "auto" }}>
                <div style={iframeContainerStyles}>
                  <YouTubeIcon style={youtubeIconStyles} />
                  <Iframe url={'https://www.youtube.com/embed/plY_fpO3kOs'}
                    width="100%"
                    height="100%"
                    display="initial"
                    position="relative"
                    title="Take a Seat overview"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page WhyTaS" id="why-Take-a-Seat">
          <h2 className="title">{t(`why.title`)}</h2>
          <div className="content">
            <div className="col">
              <Card iconName="coins" title={t(`why.revenue.0`)} description={t(`why.revenue.1`)} />
            </div>
            <div className="col">
              <Card iconName="satisfaction" title={<>{t(`why.satisfaction.0`)} {t(`why.satisfaction.1`)}</>} description={t(`why.satisfaction.2`)} />
            </div>
            <div className="col">
              <Card iconName="engage" title={t(`why.engage.0`)} description={t(`why.engage.1`)} />
            </div>
          </div>
        </div>
        <div id="solutions" className="page features-page">
          <h2 className="title">{t(`features.title`)}</h2>
          <div className="content">
            <WideCard imgName="your-menu"
              title={t(`features.digital-menu.title`)}
              description={t(`features.digital-menu.desc`)}
              linkTo="/solutions/digital-menu/"
            />
            <WideCard
              imgName="your-website"
              title={t(`features.public-page.title`)}
              description={t(`features.public-page.desc`)}
              linkTo="/solutions/website/"
            />
            <WideCard imgName="your-reservation"
              title={t(`features.reservation.title`)}
              description={t(`features.reservation.desc`)}
              linkTo="/solutions/reservations/"
            />
          </div>
        </div>
        <GreenMessage />
        <JoinUsPage />
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <>
  <DefaultGtag />
  {/* <link rel="canonical" href="https://takeaseat.io/" /> */}
  <link rel="alternate" hreflang="x-default" href="https://takeaseat.io/" />
  <link rel="alternate" hreflang="fr" href="https://takeaseat.io/fr/" />
  <link rel="alternate" hreflang="de" href="https://takeaseat.io/de/" />
  <link rel="alternate" hreflang="es" href="https://takeaseat.io/es/" />
  <title>Create Digital Menus, QR Codes, Website for Restaurant</title>
  <meta name="description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="The digital partner of restaurants and bars" />
  <meta property="og:description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
  <meta property="og:image" content="https://takeaseat.io/static/media/images/all-in-one-6" />
  <meta property="og:url" content="https://takeaseat.io/" />
  <meta property="og:site_name" content="Take a Seat" />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="The digital partner of restaurants and bars" />
  <meta name="twitter:description" content="Online solutions for restaurants and bars. Website builder, QR menus, table reservations and more." />
  <meta name="twitter:image" content="https://pbs.twimg.com/media/FEjpZgbXIAoTpq4?format=jpg&name=4096x4096" />
  <meta name="twitter:site" content="@Takeaseat_io" />
  <meta name="twitter:creator" content="@Takeaseat_io" />
</>

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["cookies","index", "green", "joinus", "navigation"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;